@import url(./reset.scss);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,200&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

#root {
  height: 100%;
}

.wrapper {
  padding: 10px;
}
